import React, { FC } from 'react';
import { Button } from 'flowbite-react';
import { HiOutlineHeart, HiHeart } from 'react-icons/hi';

const ListItem1: FC<any> = ({
  country,
  onFavoriteChange,
  processingCountries,
  onRequest,
}) => {
  const isProcessing = processingCountries.includes(country?.country?.id);

  return (
    <li className="p-3">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Button
            size="sm"
            color="gray"
            pill
            onClick={() => onFavoriteChange(country)}
          >
            {country.isFavorite ? (
              <HiHeart color="#FF5733" />
            ) : (
              <HiOutlineHeart />
            )}
          </Button>
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">
            {country.country.eng}
          </p>
          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
            Adet: {country.count}
          </p>
        </div>
        <div className="inline-flex items-center">
          <Button
            size="sm"
            color="gray"
            isProcessing={isProcessing}
            disabled={isProcessing}
            onClick={() =>
              onRequest({
                country: country.country.id,
              })
            }
          >
            + Oluştur
          </Button>
        </div>
      </div>
    </li>
  );
};

const ListItem2: FC<any> = ({
  country,
  onFavoriteChange,
  processingCountries,
  onRequest,
}) => {
  return (
    <li className="p-3">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Button
            size="sm"
            color="gray"
            pill
            onClick={() => onFavoriteChange(country)}
          >
            {country.isFavorite ? (
              <HiHeart color="#FF5733" />
            ) : (
              <HiOutlineHeart />
            )}
          </Button>
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">
            {country.countryName}
          </p>
          <div className="flex flex-col mt-2">
            {country.operators.map((operator: any, i: number) => {
              const isProcessing = processingCountries.includes(
                `${country?.countryKey}-${operator?.name}`,
              );
              return (
                <div
                  className="flex items-center justify-between hover:bg-gray-100 py-1 px-2 rounded-md"
                  key={`operator-${i}`}
                >
                  <div className="text-sm pr-2">{i + 1}</div>
                  <div className="text-sm font-medium text-gray-900 flex-1">
                    {operator.name}
                  </div>
                  <div className="text-sm text-gray-500 truncate dark:text-gray-400 flex-1">
                    Adet: {operator.count}
                  </div>
                  <div>
                    <Button
                      size="xs"
                      color="gray"
                      isProcessing={isProcessing}
                      disabled={isProcessing}
                      onClick={() =>
                        onRequest({
                          country: country.countryKey,
                          operator: operator.name,
                          key: i,
                        })
                      }
                    >
                      + Oluştur
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};

export const CountryListItem: FC<any> = ({
  country,
  onRequest,
  onFavoriteChange,
  processingCountries,
  selectedService,
}) => {
  if (selectedService === 1) {
    return (
      <ListItem1
        country={country}
        onFavoriteChange={onFavoriteChange}
        processingCountries={processingCountries}
        onRequest={onRequest}
        selectedService={selectedService}
      />
    );
  } else {
    return (
      <ListItem2
        country={country}
        onFavoriteChange={onFavoriteChange}
        processingCountries={processingCountries}
        onRequest={onRequest}
        selectedService={selectedService}
      />
    );
  }
};
