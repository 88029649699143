import { FC, useMemo, useCallback } from 'react';
import { Button, Card } from 'flowbite-react';
import { HiCheck, HiOutlineX } from 'react-icons/hi';
import { AsYouType } from 'libphonenumber-js';
import Countdown, { zeroPad } from 'react-countdown';

interface Props {
  data: any[];
  onCancel: (id: string, data: any) => void;
  onConfirm: (id: string, data: any) => void;
}

const SERVICE_STATUSES = {
  SMS_RECEIVED: 'SMS_RECEIVED',
  SERVICE_ONE: {
    CANCEL: 8,
    CONFIRM: 6,
  },
  DEFAULT: {
    CANCEL: 'cancel',
    CONFIRM: 'finish',
  },
};

const formatSmsCode = (smsCode: any) => {
  if (!smsCode) return '--- ---';
  const code = Array.isArray(smsCode) ? smsCode[0] : smsCode;
  return code.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const formatPhoneNumber = (number: string) => {
  return new AsYouType().input(`+${number}`);
};

export const VirtualNumberActivationsList: FC<Props> = ({
  data,
  onCancel,
  onConfirm,
}: Props) => {
  const handleCancel = useCallback(
    (id: string, status: any) => {
      onCancel(id, { status });
    },
    [onCancel],
  );

  const handleConfirm = useCallback(
    (id: string, status: any) => {
      onConfirm(id, { status });
    },
    [onConfirm],
  );

  const formattedActivations = useMemo(
    () =>
      data.map((activation) => {
        const { id, number, smsCode, expiresDate, createdAtDate, service } =
          activation;

        const expiryTime = new Date(expiresDate).getTime();
        const creationTime = new Date(createdAtDate).getTime();
        const timeWindow = expiryTime - creationTime;

        return {
          id,
          formattedNumber: formatPhoneNumber(number),
          formattedSmsCode: formatSmsCode(smsCode),
          countdownTime: expiryTime,
          timeWindow,
          status: activation.status,
          service,
        };
      }),
    [data],
  );

  if (!data?.length) return null;

  return (
    <div className="mb-4">
      <div className="grid grid-cols-3 w-auto gap-4">
        {formattedActivations.map((activation) => {
          const isServiceOne = activation.service === 1;
          const cancelStatus = isServiceOne
            ? SERVICE_STATUSES.SERVICE_ONE.CANCEL
            : SERVICE_STATUSES.DEFAULT.CANCEL;
          const confirmStatus = isServiceOne
            ? SERVICE_STATUSES.SERVICE_ONE.CONFIRM
            : SERVICE_STATUSES.DEFAULT.CONFIRM;

          return (
            <Countdown
              key={activation.id}
              date={activation.countdownTime}
              renderer={({ minutes, seconds, completed, total }) => (
                <Card className={completed ? 'bg-red-100' : ''}>
                  <div className="flex items-center gap-3 justify-between">
                    <div>{activation.formattedNumber}</div>
                    <div>{activation.formattedSmsCode}</div>
                    <div>
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </div>
                    {activation.status === SERVICE_STATUSES.SMS_RECEIVED ? (
                      <Button
                        size="xs"
                        color="blue"
                        onClick={() =>
                          handleConfirm(activation.id, confirmStatus)
                        }
                      >
                        <HiCheck />
                      </Button>
                    ) : (
                      <Button
                        size="xs"
                        color="red"
                        onClick={() =>
                          handleCancel(activation.id, cancelStatus)
                        }
                        disabled={total > activation.timeWindow}
                      >
                        <HiOutlineX />
                      </Button>
                    )}
                  </div>
                </Card>
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
