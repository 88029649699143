import React, { FC } from 'react';
import { CountryListItem } from './CountryListItem';

export const CountryList: FC<any> = ({
  countries,
  onRequest,
  onFavoriteChange,
  selectedService,
  processingCountries,
}) => {
  return (
    <ul className="divide-y divide-gray-200">
      {countries.map((country: any, i: number) => {
        return (
          <CountryListItem
            key={`modalCountry${i}`}
            country={country}
            onRequest={onRequest}
            onFavoriteChange={onFavoriteChange}
            processingCountries={processingCountries}
            selectedService={selectedService}
          />
        );
      })}
    </ul>
  );
};
