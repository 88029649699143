import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Spinner, Table } from 'flowbite-react';
import {
  HiCheck,
  HiExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineLocationMarker,
  HiOutlinePhone,
} from 'react-icons/hi';
import { ClientStatus, TimeAgo } from '../../../components';
import { getDevices } from '../../../services';
import { IDeviceListFilter } from '../../../types';

interface IDeviceListTable {}

export const DeviceListTable: FC<IDeviceListTable> = () => {
  const [devices, setDevices] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchDevices = async (params?: IDeviceListFilter | undefined) => {
    try {
      const response: any = await getDevices(params);

      setDevices(response.data);
    } catch (error) {
      console.error('fetchDevices:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDevices();
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Table striped hoverable>
          <Table.Head>
            <Table.HeadCell>Id</Table.HeadCell>
            <Table.HeadCell>Adı</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
            <Table.HeadCell>Son Mesaj</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>Gönderim</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {devices.map((device: any) => {
              const {
                _id: id,
                name,
                number,
                clientStatus,
                ip,
                lastMessage,
                isMessageSent,
              } = device;
              return (
                <Table.Row key={id}>
                  <Table.Cell className="whitespace-nowrap">{id}</Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>
                    {number && (
                      <div className="flex items-center">
                        <HiOutlinePhone className="mr-2" /> {number}
                      </div>
                    )}
                    <div className="mt-2 flex items-center">
                      <HiOutlineLocationMarker className="mr-2" />
                      {ip}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap">
                    {lastMessage && (
                      <div className="flex items-center">
                        <TimeAgo date={lastMessage?.createdAt} />
                        {lastMessage.isAnswered
                          ? ' / Cevapladı'
                          : ' / Gönderildi'}
                      </div>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center">
                      {ClientStatus(clientStatus)}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center">
                      <Badge
                        color={isMessageSent ? 'success' : 'warning'}
                        icon={isMessageSent ? HiCheck : HiExclamationCircle}
                      >
                        <p className="uppercase">
                          {isMessageSent ? 'Aktif' : 'Pasif'}
                        </p>
                      </Badge>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="flex items-center">
                      <Button
                        size="sm"
                        color="light"
                        as={Link}
                        to={`/device/${id}`}
                        title="Cihaz Detay"
                      >
                        <HiOutlineInformationCircle />
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
};
