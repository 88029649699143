import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'flowbite-react';

import { IStatistics } from '../../../types';

interface StatisticsModalProps {
  isOpen: boolean;
  onClose: () => void;
  statistics?: IStatistics;
  isLoading: boolean;
  onRefresh: () => void;
}

export const StatisticsModal: FC<StatisticsModalProps> = ({
  isOpen,
  onClose,
  statistics,
  isLoading,
  onRefresh,
}) => {
  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header>İstatistikler</Modal.Header>
      <Modal.Body className="text-sm">
        {/* daily message start */}
        <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
          Günlük gönderilen mesaj
        </h5>
        <div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Mesaj Kuyruk Sayısı</span>
            <Link to="/queue-distribution">{statistics?.queueCount || 0}</Link>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Toplam Mesaj</span>
            <span>{statistics?.daily.totalMessage || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Betkanyon</span>
            <span>{statistics?.daily.messageCountBySiteId.betkanyon || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Winxbet</span>
            <span>{statistics?.daily.messageCountBySiteId.winxbet || 0}</span>
          </div>
          {/* <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>WinxbetBr</span>
            <span>
              {statistics?.daily.messageCountBySiteId.winxbetBr || 0}
            </span>
          </div> */}
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Bahisal</span>
            <span>{statistics?.daily.messageCountBySiteId.bahisal || 0}</span>
          </div>
          {/* <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Unibahis</span>
            <span>
              {statistics?.daily.messageCountBySiteId.unibahis || 0}
            </span>
          </div> */}
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Betloto</span>
            <span>{statistics?.daily.messageCountBySiteId.betloto || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Kasino</span>
            <span>{statistics?.daily.messageCountBySiteId.kasino || 0}</span>
          </div>
        </div>
        {/* daily message end */}
        <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
          Cihazlar
        </h5>
        <div>
          <div className="justify-between flex hover:bg-slate-100 p-2">
            <span>Toplam</span>
            <span>{statistics?.devices.total || 0}</span>
          </div>
          <div className="justify-between flex">
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Connected</span>
              <span>{statistics?.devices.connected || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Unpaired</span>
              <span>{statistics?.devices.unpaired || 0}</span>
            </div>
          </div>
          <div className="justify-between flex">
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Error</span>
              <span>{statistics?.devices.error || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Opening</span>
              <span>{statistics?.devices.opening || 0}</span>
            </div>
          </div>
        </div>
        {/* <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
          Sitelere göre bağlı cihaz sayısı
        </h5>
        <div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Betkanyon</span>
            <span>{statistics?.sites.betkanyon || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Winxbet</span>
            <span>{statistics?.sites.winxbet || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>WinxbetBr</span>
            <span>{statistics?.sites.winxbetBr || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Bahisal</span>
            <span>{statistics?.sites.bahisal || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Unibahis</span>
            <span>{statistics?.sites.unibahis || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Betloto</span>
            <span>{statistics?.sites.betloto || 0}</span>
          </div>
        </div> */}
        <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
          Mesajlar
        </h5>
        <div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Toplam</span>
            <span>{statistics?.totalMessage || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>İletilen</span>
            <span>{statistics?.totalSentMessages || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Görülen</span>
            <span>{statistics?.totalMessagesSeen || 0}</span>
          </div>
          <div className="w-full justify-between flex hover:bg-slate-100 p-2">
            <span>Kişi Kartı</span>
            <span>{statistics?.totalContactCardSent || 0}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          isProcessing={isLoading}
          disabled={isLoading}
          onClick={onRefresh}
          color="light"
        >
          Yenile
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
