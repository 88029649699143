import React, { FC } from 'react';
import { Modal } from 'flowbite-react';
import { CountryList } from './CountryList';

const LoadingSkeleton = () => {
  return (
    <div className="animate-pulse">
      {[...Array(8)].map((_, index) => (
        <div key={`skeleton-${index}`} className="p-3 border-b border-gray-200">
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
            </div>
            <div className="flex-1 min-w-0">
              <div className="h-4 bg-gray-200 rounded w-1/3 mb-2"></div>
              <div className="h-3 bg-gray-200 rounded w-1/4"></div>
            </div>
            <div className="inline-flex items-center">
              <div className="w-20 h-8 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const CountryModal: FC<any> = ({
  isOpen,
  onClose,
  countries,
  onRequest,
  onFavoriteChange,
  isLoading,
  selectedService,
  processingCountries,
}) => {
  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header>Numara Oluştur - Servis {selectedService}</Modal.Header>
      <Modal.Body className="p-2">
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <CountryList
            countries={countries}
            onRequest={onRequest}
            onFavoriteChange={onFavoriteChange}
            selectedService={selectedService}
            processingCountries={processingCountries}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
