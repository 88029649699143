import { FC, useEffect, useCallback, useState, useMemo } from 'react';
import {
  createSearchParams,
  Navigate,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { HiFilter, HiPlus } from 'react-icons/hi';
import {
  Button,
  Card,
  Dropdown,
  Select,
  Spinner,
  Table,
  TextInput,
} from 'flowbite-react';
import CountUp from 'react-countup';

import { CardContainer } from '../../layout';
import {
  ClientStatus,
  VirtualNumberActivationsList,
  CountryModal,
} from '../../components';
import { useGlobal } from '../../hooks/useGlobal';
import { canView } from '../../utils/permissions';
import { formatDate } from '../../utils';
import {
  getNumbers,
  getNumberStatistics,
  getVirtualNumberCountries,
  getVirtualNumber,
  getVirtualNumberActivations,
  updateStatusVirtualNumber,
  removeFavoriteCountry,
  selectFavoriteCountry,
  virtualNumberBalance,
} from '../../services';

interface ITimePeriod {
  key: string;
  label: string;
}

const timePeriods: ITimePeriod[] = [
  { key: 'thisWeek', label: 'Bu Hafta' },
  { key: 'lastWeek', label: 'Geçen Hafta' },
  { key: 'thisMonth', label: 'Bu Ay' },
  { key: 'lastMonth', label: 'Geçen Ay' },
];

const numberTypes: ITimePeriod[] = [
  { key: 'virtual', label: 'Sanal' },
  { key: 'physical', label: 'Fiziksel' },
];

interface INumberFilter {
  number: string;
  status: string;
}

const initialNumberFilter: INumberFilter = {
  number: '',
  status: '',
};

export const NumbersMain: FC = () => {
  const navigate = useNavigate();
  const { user, showAlert } = useGlobal();
  const [searchParams] = useSearchParams();
  const [modalCountriesOpen, setModalCountriesOpen] = useState<boolean>(false);
  const [modalCountriesLoading, setModalCountriesLoading] =
    useState<boolean>(false);
  const [modalCountries, setModalCountries] = useState<any[]>([]);
  const [processingCountries, setProcessingCountries] = useState<string[]>([]);
  const [virtualNumberActivations, setVirtualNumberActivations] = useState<
    any[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('thisMonth');
  const [selectedNumberType, setSelectedNumberType] = useState<string>('');
  const [numbers, setNumbers] = useState<any[]>([]);
  const [statistics, setStatistics] = useState({
    totalNumbersCount: 0,
    virtualNumbersCount: 0,
    physicalNumbersCount: 0,
    totalTime: 0,
  });
  const [numberFilter, setNumberFilter] =
    useState<INumberFilter>(initialNumberFilter);
  const [withVerification, setWithVerification] = useState<boolean>(false);
  const [balance, setBalance] = useState<number[]>([0, 0, 0]);
  const [selectedService, setSelectedService] = useState<number>(0);

  // Redirect to home if user has no permission to view numbers page
  if (!canView(user, 'numbers')) {
    return <Navigate to="/" />;
  }

  // get page actions from user permissions
  const pageAction = useMemo(() => {
    return user?.permissions.pages.numbers.actions;
  }, [user]);

  // fetch numbers
  const fetchNumbers = useCallback(async () => {
    setLoading(true);

    try {
      let filter: any = {
        period: selectedPeriod,
        ...(selectedNumberType ? { numberType: selectedNumberType } : {}),
        sortBy: 'createdAt',
        sortOrder: 'DESC',
      };

      searchParams.forEach((value, key) => {
        filter[key] = value;
      });

      const [numberResponse, statisticsResponse]: any = await Promise.all([
        getNumbers(filter),
        getNumberStatistics({ period: selectedPeriod }),
      ]);

      setNumbers(numberResponse.data);
      setStatistics(statisticsResponse.data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('fetchNumbers error:', error.message);
      } else {
        console.error('fetchNumbers unknown error:', error);
      }
    } finally {
      setLoading(false);
    }
  }, [selectedPeriod, selectedNumberType, searchParams]);

  // fetch virtual number countries
  const fetchVirtualNumberCountries = async (service: number) => {
    setModalCountriesLoading(true);
    try {
      const countriesResponse: any = await getVirtualNumberCountries(service, {
        ...(withVerification ? { verification: true } : {}),
      });
      setModalCountries(countriesResponse.data);
    } catch (error) {
      console.error('fetchVirtualNumberCountries:', error);
    } finally {
      setModalCountriesLoading(false);
    }
  };

  // handle open country modal
  const handleOpenCountryModal = (service: number) => {
    setSelectedService(service);
    setModalCountriesOpen(true);
    fetchVirtualNumberCountries(service);
  };

  // fetch virtual number activations
  const fetchVirtualNumberActivations = async () => {
    try {
      const result: any = await getVirtualNumberActivations();
      setVirtualNumberActivations(result?.data || []);
    } catch (error) {
      console.log('fetchVirtualNumberActivations: ', error);
    }
  };

  // new number request
  const handleVirtualNumberRequest = async (data: any) => {
    const progressKey =
      selectedService === 1 ? data.country : `${data.country}-${data.operator}`;

    setProcessingCountries((prev) => [...prev, progressKey]);

    try {
      const payload = {
        country: data.country,
        ...(selectedService === 2 && { operator: data.operator }),
      };

      await getVirtualNumber(payload, selectedService);

      showAlert({
        text: 'Numara başarıyla oluşturuldu',
        icon: 'success',
      });
    } catch (error: any) {
      showAlert({
        text:
          error?.data?.error ||
          'İşleminiz sırasında hata oluştu, tekrar deneyin',
        icon: 'warning',
      });
    } finally {
      await fetchVirtualNumberActivations();

      setProcessingCountries((prev) =>
        prev.filter((key) => key !== progressKey),
      );
    }
  };

  // update virtual number status
  const handleVirtualNumberUpdateStatus = async (id: string, payload: any) => {
    try {
      await updateStatusVirtualNumber(id, payload);
    } catch (error) {
      console.log('handleVirtualNumberUpdateStatus', error);
    } finally {
      await fetchVirtualNumberActivations();
    }
  };

  // handle period change
  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
  };

  // handle number type change
  const handleNumberTypeChange = (type: string) => {
    setSelectedNumberType((prevType) => (prevType === type ? '' : type));
  };

  // handle filter
  const filterHandler = () => {
    const filtered: any = Object.fromEntries(
      Object.entries(numberFilter).filter(([_, value]) => value !== ''),
    );

    navigate({
      pathname: '/numbers',
      search: createSearchParams(filtered).toString(),
    });
  };

  // handle favorite change
  const handleFavoriteChange = async (item: any) => {
    let countryId: any = null;

    if (selectedService === 1) {
      countryId = item.country.id;
    } else {
      countryId = item.countryKey;
    }

    if (item.isFavorite) {
      await removeFavoriteCountry(countryId, selectedService);
    } else {
      await selectFavoriteCountry(countryId, selectedService);
    }

    // fetch virtual number countries
    await fetchVirtualNumberCountries(selectedService);
  };

  const getVirtualNumberBalance = async () => {
    const balanceResponse: any = await virtualNumberBalance();

    setBalance(balanceResponse?.data || [0, 0, 0]);
  };

  useEffect(() => {
    fetchNumbers();
  }, [searchParams, selectedPeriod, selectedNumberType]);

  useEffect(() => {
    getVirtualNumberBalance();
    fetchVirtualNumberActivations();
  }, []);

  // fetch virtual number activations every 10 seconds
  useEffect(() => {
    let intervalId: any = null;

    if (virtualNumberActivations.length > 0 && !intervalId) {
      intervalId = setInterval(fetchVirtualNumberActivations, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [virtualNumberActivations]);

  return (
    <>
      <div className="items-center flex justify-between mb-4">
        <div className="flex"></div>
        <div className="flex gap-4">
          <Button.Group>
            {numberTypes.map((item, i) => (
              <Button
                color={selectedNumberType === item.key ? 'dark' : 'light'}
                key={`numberTypeBtn-${i}`}
                onClick={() => handleNumberTypeChange(item.key)}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
          <Button.Group>
            {timePeriods.map((item, i) => (
              <Button
                color={selectedPeriod === item.key ? 'dark' : 'light'}
                key={`periodBtn-${i}`}
                onClick={() => handlePeriodChange(item.key)}
              >
                {item.label}
              </Button>
            ))}
          </Button.Group>
        </div>
      </div>
      <div className="grid w-full grid-cols-4 gap-4 mb-4">
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Toplam Süre
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.totalTime} /> saat
              </span>
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Fiziksel Numara
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.physicalNumbersCount} />
              </span>
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Sanal Numara
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.virtualNumbersCount} />
              </span>
            </div>
          </div>
        </Card>
        <Card>
          <div>
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Toplam Numara
            </h3>
            <div>
              <span className="text-2xl font-bold leading-none text-gray-900">
                <CountUp end={statistics.totalNumbersCount} />
              </span>
            </div>
          </div>
        </Card>
      </div>

      {pageAction && pageAction.createNumber && (
        <VirtualNumberActivationsList
          data={virtualNumberActivations}
          onCancel={(id: string, data: any) =>
            handleVirtualNumberUpdateStatus(id, data)
          }
          onConfirm={(id: string, data: any) =>
            handleVirtualNumberUpdateStatus(id, data)
          }
        />
      )}

      <CardContainer title="Numaralar">
        <div className="items-center flex justify-between">
          <div className="flex gap-4">
            <TextInput
              type="text"
              placeholder="Numara Ara"
              value={numberFilter.number}
              onChange={(e) => {
                setNumberFilter({
                  ...numberFilter,
                  number: e.target.value,
                });
              }}
            />
            <Select
              onChange={(e: any) => {
                setNumberFilter({
                  ...numberFilter,
                  status: e.target.value,
                });
              }}
            >
              <option value="">Status</option>
              <option value="CONNECTED">Connected</option>
              <option value="DISCONNECTED">Disconnected</option>
              <option value="PENDING">Pending</option>
              <option value="EXPIRED">Expired</option>
              <option value="FAILED">Failed</option>
              <option value="CANCELLED">Cancelled</option>
              <option value="COMPLETED">Completed</option>
              <option value="SMS_RECEIVED">SMS Received</option>
            </Select>
            <Button color="light" onClick={() => filterHandler()}>
              <HiFilter />
            </Button>
          </div>
          <div className="flex">
            {pageAction && pageAction.createNumber && (
              <div className="flex gap-4 items-center">
                {/* <div className="text-lg font-bold"> Bakiye: {balance} ₽</div> */}
                <Dropdown label="Numara Oluştur" color="blue">
                  <Dropdown.Item onClick={() => handleOpenCountryModal(1)}>
                    <HiPlus className="mr-2" />
                    Servis 1 ({balance[0]} ₽)
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleOpenCountryModal(2)}>
                    <HiPlus className="mr-2" />
                    Servis 2 ({balance[1]} ₽)
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleOpenCountryModal(3)}>
                    <HiPlus className="mr-2" />
                    Servis 3 ({balance[2]} ₽)
                  </Dropdown.Item>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        {/* date picker */}
        <div className="mt-4">
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <Table striped hoverable>
              <Table.Head>
                <Table.HeadCell>Numara</Table.HeadCell>
                <Table.HeadCell>Tip</Table.HeadCell>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell>Connected</Table.HeadCell>
                <Table.HeadCell>Disconnected</Table.HeadCell>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell>
                  <div className="text-center">Toplam Mesaj</div>
                </Table.HeadCell>
                <Table.HeadCell>
                  <div className="text-center">Kişi Kart</div>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {numbers.map((data: any) => {
                  const {
                    _id: id,
                    number,
                    numberType,
                    status,
                    connectionDate,
                    disconnectionDate,
                    messageCount,
                    contactCardCount,
                    totalConnectedTime,
                  } = data;
                  return (
                    <Table.Row
                      key={id}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <Table.Cell>{number}</Table.Cell>
                      <Table.Cell>
                        {numberType === 'physical' ? 'Fiziksel' : 'Sanal'}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="inline-block">
                          {ClientStatus(status)}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {formatDate(connectionDate, 'DD.MM.YYYY HH:mm:ss')}
                      </Table.Cell>
                      <Table.Cell>
                        {disconnectionDate
                          ? formatDate(disconnectionDate, 'DD.MM.YYYY HH:mm:ss')
                          : null}
                      </Table.Cell>
                      <Table.Cell>{totalConnectedTime}</Table.Cell>
                      <Table.Cell>
                        <div className="text-center">{messageCount}</div>
                      </Table.Cell>
                      <Table.Cell>
                        <div className="text-center">{contactCardCount}</div>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          )}
        </div>
      </CardContainer>

      <CountryModal
        isOpen={modalCountriesOpen}
        onClose={() => setModalCountriesOpen(false)}
        countries={modalCountries}
        onRequest={handleVirtualNumberRequest}
        isLoading={modalCountriesLoading}
        selectedService={selectedService}
        processingCountries={processingCountries}
        onFavoriteChange={handleFavoriteChange}
      />
    </>
  );
};
