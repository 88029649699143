import React, { FC } from 'react';
import { Card } from 'flowbite-react';
import { HiOutlineInformationCircle, HiArrowSmUp } from 'react-icons/hi';
import CountUp from 'react-countup';

import { IStatistics } from '../../../types';

interface IStatisticsCardsProps {
  statistics?: IStatistics;
  onOpenStatisticsModal: () => void;
}

export const StatisticsCards: FC<IStatisticsCardsProps> = ({
  statistics,
  onOpenStatisticsModal,
}) => {
  return (
    <div className="grid w-full grid-cols-4 gap-4 mb-4">
      <Card>
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-base font-medium text-gray-500">İstatistikler</h3>
          <div onClick={onOpenStatisticsModal} className="cursor-pointer">
            <HiOutlineInformationCircle />
          </div>
        </div>
        <div className="flex justify-between mb-2">
          <div className="text-sm font-normal text-gray-500">Toplam:</div>
          <div className="text-sm font-medium">
            <CountUp end={statistics?.devices.total || 0} />
          </div>
        </div>
        <div className="flex justify-between mb-2 gap-4">
          <div className="flex justify-between w-full">
            <div className="text-sm font-normal text-gray-500">Connected:</div>
            <div className="text-sm font-medium">
              <CountUp end={statistics?.devices.connected || 0} />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-sm font-normal text-gray-500">Unpaired:</div>
            <div className="text-sm font-medium">
              <CountUp end={statistics?.devices.unpaired || 0} />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-2 gap-4">
          <div className="flex justify-between w-full">
            <div className="text-sm font-normal text-gray-500">Opening:</div>
            <div className="text-sm font-medium">
              <CountUp end={statistics?.devices.opening || 0} />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-sm font-normal text-gray-500">Error:</div>
            <div className="text-sm font-medium">
              <CountUp end={statistics?.devices.error || 0} />
            </div>
          </div>
        </div>
      </Card>

      <Card>
        <div className="items-center">
          <h3 className="mb-2 text-base font-medium text-gray-500">
            Toplam Mesaj
          </h3>
          <div className="mb-2">
            <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">
              <CountUp end={statistics?.totalMessage || 0} />
            </span>
          </div>
          <div>
            <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
              <span className="flex items-center mr-2 text-sm text-green-500 dark:text-green-400">
                <HiArrowSmUp className="w-4" />
                0%
              </span>
              Geçen aya göre
            </p>
          </div>
        </div>
      </Card>

      <Card>
        <div className="items-center">
          <h3 className="mb-2 text-base font-medium text-gray-500">
            İletilen Mesaj
          </h3>
          <div className="mb-2">
            <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">
              <CountUp end={statistics?.totalSentMessages || 0} />
            </span>
          </div>
          <div>
            <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
              <span className="flex items-center mr-2 text-sm text-green-500 dark:text-green-400">
                <HiArrowSmUp className="w-4" />
                0%
              </span>
              Geçen aya göre
            </p>
          </div>
        </div>
      </Card>

      <Card>
        <div className="items-center">
          <h3 className="mb-2 text-base font-medium text-gray-500">
            Kişi Kartı
          </h3>
          <div className="mb-2">
            <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">
              <CountUp end={statistics?.totalContactCardSent || 0} />
            </span>
          </div>
          <div>
            <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
              <span className="flex items-center mr-2 text-sm text-green-500 dark:text-green-400">
                <HiArrowSmUp className="w-4" />
                0%
              </span>
              Geçen aya göre
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};
