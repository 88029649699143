import { FC, useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { HiFilter, HiOutlineCog } from 'react-icons/hi';
import { Button, Select, TextInput } from 'flowbite-react';

import {
  getQueueSchedule,
  getQuickStatistics,
  updateQueueSchedule,
} from '../../services';
import { IDeviceListFilter, IStatistics } from '../../types';
import { useGlobal } from '../../hooks/useGlobal';
import { Msg } from '../../consts';
import { CardContainer } from '../../layout';
import {
  StatisticsModal,
  StatisticsCards,
  DeviceListTable,
} from './components';

const initialFilter: IDeviceListFilter = {
  q: '',
  clientStatus: '',
  siteId: '',
  ek: '',
  proxyUrl: '',
};

export const DashboardPage: FC = () => {
  const { showAlert } = useGlobal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [statisticModal, showStatisticModal] = useState<boolean>(false);
  const [statisticsLoading, setStatisticsLoading] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<IStatistics>();
  const [deviceFilter, setDeviceFilter] =
    useState<IDeviceListFilter>(initialFilter);
  const [queueSchedule, setQueueSchedule] = useState<boolean>(false);
  const [queueScheduleLoading, setQueueScheduleLoading] =
    useState<boolean>(false);

  const filterHandler = () => {
    const filteredDeviceFilter: any = Object.fromEntries(
      Object.entries(deviceFilter).filter(([_, value]) => value !== ''),
    );

    if (Object.keys(filteredDeviceFilter).length > 0) {
      navigate({
        pathname: '/',
        search: createSearchParams(filteredDeviceFilter).toString(),
      });
    }
  };

  // update queue schedule
  const handleUpdateQueueSchedule = async () => {
    showAlert({
      text: `Mesaj gönderimi ${queueSchedule ? 'kapatılacak' : 'açılacak'}`,
      icon: 'warning',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setQueueScheduleLoading(true);

        try {
          await updateQueueSchedule({ enabled: !queueSchedule });

          setQueueSchedule(!queueSchedule);

          showAlert({
            text: Msg.Success,
            icon: 'success',
          });
        } catch (error) {
          console.error('handleUpdateQueueSchedule:', error);
        } finally {
          setQueueScheduleLoading(false);
        }
      }
    });
  };

  // fetch statistics
  const fetchStatistics = async () => {
    try {
      setStatisticsLoading(true);
      const response: any = await getQuickStatistics();
      setStatistics(response.data);
    } catch (error) {
      console.error('fetchStatistics: ', error);
    } finally {
      setStatisticsLoading(false);
    }
  };

  // fetch queue schedule
  const fetchQueueSchedule = async () => {
    setQueueScheduleLoading(true);

    try {
      const response: any = await getQueueSchedule();
      setQueueSchedule(response.data?.queueSchedule || false);
    } catch (error) {
      console.error('fetchQueueSchedule:', error);
    } finally {
      setQueueScheduleLoading(false);
    }
  };

  const handleOpenStatisticsModal = () => {
    showStatisticModal(true);
  };

  useEffect(() => {
    fetchQueueSchedule();
    fetchStatistics();
  }, []);

  useEffect(() => {
    const filter: any = {};
    searchParams.forEach((value, key) => {
      filter[key] = value;
    });

    setDeviceFilter(filter);
  }, [searchParams]);

  return (
    <>
      <StatisticsCards
        statistics={statistics}
        onOpenStatisticsModal={handleOpenStatisticsModal}
      />

      <CardContainer title="Cihaz Listesi">
        {/* device filter  */}
        <div className="items-center flex justify-between">
          <div className="flex gap-4">
            <TextInput
              placeholder="Ad, Ip ya da Numara"
              value={deviceFilter.q || ''}
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  q: e.target.value,
                });
              }}
            />
            <Select
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  clientStatus: e.target.value,
                });
              }}
            >
              <option value="">Status</option>
              <option value="CONNECTED">Connected</option>
              <option value="UNPAIRED">Unpaired</option>
              <option value="ERROR">Error</option>
              <option value="OPENING">Opening</option>
              <option value="RESTART">Restart</option>
            </Select>
            <Button color="light" onClick={filterHandler}>
              <HiFilter />
            </Button>
          </div>

          {/* queue schedule option */}
          <div className="items-center flex gap-2">
            <Button
              color="blue"
              onClick={handleUpdateQueueSchedule}
              isProcessing={queueScheduleLoading}
              disabled={queueScheduleLoading}
              size="md"
            >
              <HiOutlineCog className="mr-2" />
              Kuyruk: {queueSchedule ? 'Açık' : 'Kapalı'}
            </Button>
          </div>
        </div>

        {/* device list */}
        <div className="flex flex-col mt-4">
          <div className="rounded-lg">
            <DeviceListTable />
          </div>
        </div>
      </CardContainer>

      {/* İstatistik Modalı */}
      <StatisticsModal
        isOpen={statisticModal}
        onClose={() => showStatisticModal(false)}
        statistics={statistics}
        isLoading={statisticsLoading}
        onRefresh={fetchStatistics}
      />
    </>
  );
};
