import React, { FC, useEffect, useState } from 'react';
import { CardContainer } from '../../layout';
import { applyQueueDistribution, getQueueDistributions } from '../../services';
import { Badge, Button, Spinner, Table } from 'flowbite-react';
import { TimeAgo } from '../../components';
import { HiClock } from 'react-icons/hi';

const listBadge = (status: string) => {
  switch (status) {
    case 'pending':
      return (
        <Badge color="warning" icon={HiClock}>
          Pending
        </Badge>
      );
    default:
      return <Badge color="success">Success</Badge>;
  }
};

const listSite = (siteId: number) => {
  const sites: Record<number, string> = {
    1: 'Betkanyon',
    2: 'Winxbet',
    3: 'Bahisal',
    4: 'WinxbetBr',
    5: 'Unibahis',
    7: 'Betloto',
    8: 'Kasino',
  };

  return sites[siteId];
};

export const QueueDistribution: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [queueDistributions, setQueueDistributions] = useState<any[]>([]);
  const [queueCount, setQueueCount] = useState<number>(0);
  const [loadingButtons, setLoadingButtons] = useState<Record<string, boolean>>(
    {},
  );

  const fetchQueueDistributions = async () => {
    try {
      const response: any = await getQueueDistributions();
      setQueueDistributions(response?.data?.pendingInvitations || []);
      setQueueCount(response?.data?.count || 0);
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async (id: string) => {
    try {
      setLoadingButtons((prev) => ({ ...prev, [id]: true }));

      await applyQueueDistribution({
        id,
      });
    } catch (error) {
      console.error('Gönderme işlemi başarısız:', error);
    } finally {
      // remove loading button
      setLoadingButtons((prev) => ({ ...prev, [id]: false }));

      // remove queue distribution
      setQueueDistributions((prev) => prev.filter((item) => item._id !== id));

      // update queue count
      setQueueCount(queueCount - 1);
    }
  };

  useEffect(() => {
    fetchQueueDistributions();
  }, []);

  return (
    <CardContainer title={`Mesaj Kuyruğu (${queueCount})`}>
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Table striped hoverable className="table-fixed w-full">
          <Table.Body>
            {queueDistributions.map((queueDistribution: any) => {
              const { status } = queueDistribution;
              const isButtonLoading =
                loadingButtons[queueDistribution._id] || false;

              return (
                <Table.Row key={queueDistribution._id}>
                  <Table.Cell className="w-1/5">
                    {queueDistribution._id}
                  </Table.Cell>
                  <Table.Cell className="w-1/5">
                    {listSite(queueDistribution.siteId)}
                  </Table.Cell>
                  <Table.Cell className="w-1/5">
                    <div className="flex justify-center">
                      {listBadge(status)}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="w-1/5">
                    <TimeAgo date={queueDistribution.createdAt} />
                  </Table.Cell>
                  <Table.Cell className="w-1/5">
                    <div className="flex justify-end">
                      <Button
                        size="xs"
                        color="light"
                        isProcessing={isButtonLoading}
                        onClick={() => handleSend(queueDistribution._id)}
                      >
                        Gönder
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </CardContainer>
  );
};
