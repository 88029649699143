import { SweetAlertResult } from 'sweetalert2';

export enum WAClientStatus {
  CONFLICT = 'CONFLICT',
  CONNECTED = 'CONNECTED',
  DEPRECATED_VERSION = 'DEPRECATED_VERSION',
  OPENING = 'OPENING',
  PAIRING = 'PAIRING',
  PROXYBLOCK = 'PROXYBLOCK',
  SMB_TOS_BLOCK = 'SMB_TOS_BLOCK',
  TIMEOUT = 'TIMEOUT',
  TOS_BLOCK = 'TOS_BLOCK',
  UNLAUNCHED = 'UNLAUNCHED',
  UNPAIRED = 'UNPAIRED',
  UNPAIRED_IDLE = 'UNPAIRED_IDLE',
  ERROR = 'ERROR',
  RESTART = 'RESTART',
  DISCONNECTED = 'DISCONNECTED',
}

export interface IAuth {
  username: string;
  password: string;
}

export interface IStatistics {
  date: string;
  devices: {
    total: number;
    connected: number;
    unpaired: number;
    error: number;
    opening: number;
    external: {
      total: number;
      connected: number;
      unpaired: number;
      error: number;
      opening: number;
    };
  };
  sites: {
    betkanyon: number;
    winxbet: number;
    winxbetBr: number;
    bahisal: number;
    unibahis: number;
    betloto: number;
  };
  daily: {
    totalMessage: number;
    messageCountBySiteId: {
      betkanyon: number;
      winxbet: number;
      winxbetBr: number;
      bahisal: number;
      unibahis: number;
      betloto: number;
      kasino: number;
    };
  };
  totalMessage: number;
  totalSentMessages: number;
  totalMessagesSeen: number;
  totalContactCardSent: number;
  queueCount: number;
}

export interface ICreateUser {
  name?: string;
  username?: string;
  email?: string;
  password?: string;
  status?: boolean;
}

export interface QrResponse {
  qr: string | null;
  deviceStatus: string | null;
}

export interface IDevice {
  _id: string;
  clientId: string;
  name: string;
  number: string;
  ip: string;
  port: string;
  siteId: string;
  totalMessage: number;
  dailyLimit: number;
  status: number;
  isProxy: boolean;
  isExternal: boolean;
  note: null | string;
  clientStatus: WAClientStatus;
  clientStatusUpdatedAt: number;
  createdAt: number;
  updatedAt: number;
  remote: QrResponse;
}

export interface IAlertResult extends SweetAlertResult {}

export interface IMessageTemplate {
  _id: string;
  name: string;
  messages: Array<{ text: string; id: string; date: Date }>;
  country: string;
  status: boolean;
}

export interface INewUserPayload {
  username: string;
  email: string;
  password: string;
  status: boolean;
  permissions: {
    role?: string;
    pages: {
      [key: string]: {
        showPage?: boolean;
        actions?: any;
      };
    };
  };
}

export interface IDeviceListFilter {
  q: string;
  clientStatus: string;
  siteId: string;
  ek: string;
  proxyUrl: string;
}
